var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Carousel"},[(_vm.images.length > 1)?_c('swiper',{ref:"mySwiper",staticClass:"Swiper",attrs:{"options":_vm.swiperOptions},on:{"slideChange":function($event){return _vm.onSlideChange()}}},_vm._l((_vm.images),function(image,index){return _c('swiper-slide',{key:index},[(image.video)?_c('div',{staticClass:"Carousel-slide",on:{"click":function($event){return _vm.onSelectSlide(image.target_id)}}},[_c('video',{staticClass:"Carousel-img",attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":image.url,"type":"video/mp4"}})])]):_c('div',{staticClass:"Carousel-slide",on:{"click":function($event){return _vm.onSelectSlide(image.target_id)}}},[_c('img',{staticClass:"Carousel-img",attrs:{"src":image.url}})])])}),1):_c('div',{staticClass:"Carousel-unique"},[(_vm.images[0].video)?_c('div',{staticClass:"Carousel-slide",on:{"click":function($event){return _vm.onSelectSlide(_vm.images[0].target_id)}}},[_c('video',{staticClass:"Carousel-img",style:({ 'z-index': 0 }),attrs:{"autoplay":"","loop":"","muted":"","playsinline":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.images[0].url,"type":"video/mp4"}})])]):_c('div',{staticClass:"Carousel-slide",on:{"click":function($event){return _vm.onSelectSlide(_vm.images[0].target_id)}}},[_c('img',{staticClass:"Carousel-img",attrs:{"src":_vm.images[0].url}})])]),(_vm.images.length > 1)?_c('div',{staticClass:"swiper-button-prev",style:({
      color: _vm.colorNav,
      width: 2 * _vm.factor + 'rem',
      height: 2 * _vm.factor + 'rem',
      'margin-top': -2 * _vm.factor + 'rem'
    })}):_vm._e(),(_vm.images.length > 1)?_c('div',{staticClass:"swiper-button-next",style:({
      color: _vm.colorNav,
      width: 2 * _vm.factor + 'rem',
      height: 2 * _vm.factor + 'rem',
      'margin-top': -2 * _vm.factor + 'rem'
    })}):_vm._e(),(_vm.fullscreenIcon)?_c('div',{staticClass:"Fullscreen",style:({ background: _vm.$store.getters['theme/theme'].secondary }),on:{"click":function($event){return _vm.$emit('Fullscreen')}}}):_vm._e(),(_vm.closeFullscreenIcon)?_c('div',{staticClass:"CloseFullscreen",style:({ background: _vm.$store.getters['theme/theme'].secondary }),on:{"click":function($event){return _vm.$store.dispatch('module_visit/SetFullscreenData', '')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }