export const GetJSON = async url => {
  const xhr = new XMLHttpRequest() /* eslint-disable-line */
  return new Promise(resolve => {
    xhr.onload = function () {
      const status = xhr.status
      if (status === 200) {
        return resolve(xhr.response)
      } else {
        return resolve(undefined)
      }
    }
    xhr.open('GET', url, true)
    xhr.setRequestHeader('Content-Type', 'application/xml')
    xhr.responseType = 'json'
    xhr.send()
  })
}
