<template>
  <div
    class="SideMenuButton"
    :style="{ background: $store.getters['theme/theme'].secondary }"
    @click="$emit('Clicked')"
  >
    <div
      class="SideMenuIcon"
      :style="{ background: $store.getters['theme/theme'].logoNavbar }"
    />
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
@media (orientation: landscape) {
  .SideMenuButton {
    margin-left: 80px;
    margin-top: 10px;
    top: 3em;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    pointer-events: auto;
  }
}

@media (orientation: portrait) {
  .SideMenuButton {
    margin-left: 1em;
    margin-top: 1em;
    top: 3em;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    pointer-events: auto;
  }
}

.SideMenuIcon {
  margin: auto;
  background: white;
  width: 50%;
  height: 50%;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-image: url('../../assets/icons/map.svg');
}
</style>
